<template>
  <div v-if="loading">
    <div id="load">
      <div>S</div>
      <div>E</div>
      <div>X</div>
      <div>O</div>
      <div>B</div>
      <div>T</div>
      <div>O</div>
      <div>O</div>
      <div>L</div>
    </div>
  </div>
  
  <div v-else class="app-container">
    <HeaderPanel v-if="shouldShowHeaderAndNav" />
    <div :class="shouldShowHeaderAndNav ? 'main-container' : 'container' ">
      <RouterView />
    </div>
    <NavigationPanel v-if="shouldShowHeaderAndNav" />
  </div>
  
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';

import axios from './utils/axiosConfig';
import { triggerHapticFeedback } from './utils/hapticFeedback';

import NavigationPanel from './components/NavigationPanel.vue';
import HeaderPanel from './components/HeaderPanel.vue';

const responseData = ref(null)
const loading = ref(true)
const error = ref(null)
const routesWithoutHeaderAndNav = ['/pay'];
const route = useRoute();
const store = useStore();

const shouldShowHeaderAndNav = computed(() => {
  return !routesWithoutHeaderAndNav.includes(route.path);
});

const  preloadImage = (src) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
};

onMounted(async () => {
  window.Telegram.WebApp.ready();

  timer();
  const initData = window.Telegram.WebApp.initData;

  // Отправляем данные на серверную часть
  axios.post('/authenticate/', {
    initData
  })
  .then(async response => {
    responseData.value = response.data.message;
    store.dispatch('fetchBoxesCount');
    store.dispatch('friends/fetchFriends');
    store.dispatch('winRecords/fetchWinRecords');

    await preloadImage(require('@/assets/main-bg.png'));
    await preloadImage(require('@/assets/border.svg'));
    await preloadImage(require('@/assets/locked.png'));
    await preloadImage(require('@/assets/fire.png'));
    setTimeout(() => {
      loading.value = false;
    }, 2200);
  })
  .catch(err => {
    error.value = 'Error occurred while sending data.';
    loading.value = false;
    console.log(err);
  });
});

function timer() {
  const loaderElements = document.querySelectorAll('#load div');
  
  loaderElements.forEach((element) => {
    element.addEventListener('animationstart', () => {
      if (loading.value) {
        triggerHapticFeedback("light", "impact");
      }
    });
  });

}

</script>

<style scoped>


.main-container {
  height: calc(100% - 178px);
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.container, .app-container {
  height: 100%;
}

#load {
  position:absolute;
  width:600px;
  height:36px;
  left:50%;
  top:40%;
  margin-left:-300px;
  overflow:visible;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  cursor:default;
}

#load div {
  animation-iteration-count: 1;
  animation: move 2s linear;
  position: absolute;
  width: 20px;
  height: 36px;
  font-family: Helvetica, Arial, sans-serif;
  color: #35C4F0;
}

#load div:nth-child(2) { animation-delay: 0.2s; }
#load div:nth-child(3) { animation-delay: 0.4s; }
#load div:nth-child(4) { animation-delay: 0.6s; }
#load div:nth-child(5) { animation-delay: 0.8s; }
#load div:nth-child(6) { animation-delay: 1s; }
#load div:nth-child(7) { animation-delay: 1.2s; }
#load div:nth-child(8) { animation-delay: 1.4s; }
#load div:nth-child(9) { animation-delay: 1.6s; }

@keyframes move {
  0% { left: 0; opacity: 0; transform: rotate(180deg); }
  35% { left: 41%; transform: rotate(0deg); opacity: 1; }
  65% { left: 59%; transform: rotate(0deg); opacity: 1; }
  100% { left: 100%; transform: rotate(-180deg); opacity: 0; }
}


</style>
