<template>

    <div class="atropos my-atropos" ref="atroposEl">

        <div class="atropos-scale">

          <div class="atropos-rotate">

            <div class="atropos-inner">

                <div class="bg"></div>
                
                <div v-if="boxesCount > 0 && !showSvg" class="card-name">
                    <p class="first-word">Memecoins</p>
                    <p class="second-word">Box</p>
                </div>

                <div v-else-if="boxesCount === 0" class="no-boxes-container">
                    <img class="box-icon" :src="require('@/assets/box-icon.png')" />
                    <p class="no-boxes">You have 0 boxes, buy more</p>
                </div>  
                
                <div v-if="boxesCount > 0 && !showSvg" class="image-group">
                    <!-- Отображаем первые три изображения -->
                    <div v-for="(crypto, index) in limitedImages" :key="index" class="image-wrapper">
                      <img :src="crypto.imageSrc" :alt="crypto.name" class="circle-image" />
                    </div>
                    <!-- Показать количество оставшихся -->
                    <div v-if="totalCount > 0" class="remaining-count">
                      <p>{{ totalCount }}</p>
                    </div>
                </div>

                <div v-if="showSvg || coinAnimating">
                <!-- Привязываем реф и динамический класс для анимации -->
                    <img
                        ref="coinImg"
                        :class="{'winned-coin': true, 'animate-getit': coinAnimating}"
                        :src="require(`@/assets/${wonCrypto.image}`)"
                        @animationend="onAnimationEnd"
                        alt="Won Coin"
                    />
                </div>

                <div v-if="showSvg">
                    <div class="congrats-container">
                    
                        <img class="congrats-icon" :src="require('@/assets/fire.png')" />
                        <p class="congrats">Congrats!</p>
                    
                    </div>
                </div>
                
                <img v-if="!showSvg" class="border-card" :src="require('@/assets/border.svg')" />
                <img v-else class="border-card" :src="require('@/assets/opened-border.svg')" />

                <div v-if="boxesCount === 0" class="blur-content">
                    <img class="locked-icon" data-atropos-offset="3" :src="require('@/assets/locked.png')" />
                </div>

                <div class="content">
                    <img
                        v-if="showSvg && !isAnimating"
                        class="main-bg"
                        data-atropos-offset="-5"
                        src="@/assets/opened-bg.webp"
                    />
                    <canvas
                        v-else-if="!showSvg && isAnimating"
                        ref="canvasEl"
                        class="main-bg"
                        data-atropos-offset="-5"
                    >
                    </canvas>
                    
                    <img
                        v-else
                        class="main-bg"
                        data-atropos-offset="-5"
                        src="@/assets/main-bg.png"
                    />
                </div>

                <button 
                    v-if="boxesCount > 0 && !showSvg && !isAnimating"  
                    class="open-button" 
                    data-atropos-offset="5" 
                    @click="handleOpenClick"
                    :disabled="isAnimating"
                >
                    Open
                </button>

                <div v-else-if="boxesCount === 0 && !showSvg" class="more-boxes-buttons" data-atropos-offset="5">
                    <router-link v-if="freeBoxesLeft <= 5" to="friends" custom v-slot="{ navigate }">
                        <div class="friend-button"
                            @click="navigate"    
                        >
                            <img class="friends-icon" :src="require('@/assets/friends.png')" />
                            <p class="friends-text">Ask your friends for help</p>
                        </div>
                    </router-link>
                    <router-link to="pay" custom v-slot="{ navigate }">
                        <div 
                            @click="navigate" 
                            class="buy-button">
                            <img class="buy-icon" :src="require('@/assets/buy-icon.png')" />
                            <p class="buy-text">Buy more boxes</p>
                        </div>
                    </router-link>
                </div>

                <div class="get-win-container" v-if="showSvg">
                    <div class="get-win-title">
                        + {{ wonCrypto.count }} ${{ wonCrypto.name.toUpperCase() }}
                    </div>
                </div>

                <div v-if="showSvg" class="get-win-button" data-atropos-offset="5">
                    <div class="get-button" @click="handleGetClick">
                        Get it
                    </div>
                </div>

            </div>
          </div>
        </div>
    </div>

    <div class="animation">
        <LottieAnimation
          ref="anim"
          :animation-data="ConfettiJSON"
          :loop="false"
          :auto-play="false"
          :speed="1"
          @loopComplete="loopComplete"
          @complete="complete"
          @enterFrame="enterFrame"
          @segmentStart="segmentStart"
          @stopped="stopped"/>
    </div>

    <!--<div v-if="showModal && wonCrypto" class="modal-overlay">
        <div class="modal-content">
            <img v-if="wonCrypto.image" :src="require(`@/assets/${wonCrypto.image}`)" alt="Crypto Image" class="won-crypto-image" />
            <p class="congrats-text">Congratulations! You won 1 {{ wonCrypto.name }} token!</p>
            <button @click="showModal = false" class="close-button">Close</button>
        </div>
    </div>-->
    
</template>

<script setup>
import { ref, onMounted, computed, nextTick } from 'vue';
import 'atropos/css';
import Atropos from 'atropos';
import cryptoConfig from '@/assets/cryptoConfig.json';
import axios from '../utils/axiosConfig';
import { LottieAnimation } from "lottie-web-vue"
import ConfettiJSON from '@/assets/confetti.json'
import { useStore } from 'vuex';
import { triggerHapticFeedback } from '../utils/hapticFeedback';

let anim = ref();

const store = useStore();

const boxesCount = computed(() => store.getters.boxesCount);
const freeBoxesLeft = computed(() => store.getters['friends/freeBoxesLeft'])

const showModal = ref(false);
const wonCrypto = ref(null);
const responseData = ref(null);
const loading = ref(false);
const error = ref(null);
const totalCount = computed(() => Math.max(cryptoConfig.length, 0));
const atroposInstance = ref(null);
const limitedImages = ref([]);

const coinAnimating = ref(false);

// Управление анимацией
const isAnimating = ref(false);
const frameIndex = ref(0);
const totalFrames = 70;
const showSvg = ref(false);

const atroposEl = ref(null);

// Массив, куда сложим загруженные кадры (Image)
const frames = ref([])

// Ссылка на <canvas>
const canvasEl = ref(null)

// Функция для получения первых трёх изображений
function getCryptoImages() {
  // Берём первые три элемента из массива
  const limitedCryptos = cryptoConfig.slice(0, 3);

  // Формируем массив с путями к изображениям через require
  return limitedCryptos.map((crypto) => {
    return {
      name: crypto.name,
      imageSrc: require(`@/assets/${crypto.image}`),
    };
  });
}

// 1) Загрузка одного изображения
function loadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = src
    img.onload = () => resolve(img)
    img.onerror = reject
  })
}

async function loadFrames() {
  if (frames.value.length) return
  
  const promises = []
  for (let i = 1; i <= totalFrames; i++) {
    const paddedNumber = String(i).padStart(4, '0')
    const framePath = require(`@/assets/animation/${paddedNumber}_${i}_11zon.webp`)
    promises.push(loadImage(framePath))
  }
  frames.value = await Promise.all(promises)
}

async function startAnimation() {
  // Если кадры ещё не загружены — грузим (один раз)
  if (!frames.value.length) {
    await loadFrames()
  }

  isAnimating.value = true
  frameIndex.value = 0

  // Дождёмся, пока DOM переключит <canvas>
  await nextTick()

  const canvas = canvasEl.value
  const ctx = canvas.getContext('2d')

  // Берём размер из первого кадра
  const { width, height } = frames.value[0]
  canvas.width = width
  canvas.height = height

  const fps = 30
  const interval = setInterval(() => {
    ctx.clearRect(0, 0, width, height)
    ctx.drawImage(frames.value[frameIndex.value], 0, 0)

    frameIndex.value++

    // Когда дошли до последнего кадра — останавливаем
    if (frameIndex.value >= totalFrames) {
      clearInterval(interval)
      isAnimating.value = false
      showSvg.value = true
    }
  }, 1000 / fps)
}

// Запрос на получение коробки
const getNextUnopenedBox = async () => {
    try {
        const response = await axios.get('/open-box/');
        return response.data; // Возвращаем коробку
    } catch (err) {
        console.error('Error fetching next unopened box:', err);
        throw err;
    }
};

const handleGetClick = () => {
    showSvg.value = false;
    coinAnimating.value = true;
}

onMounted( async () => {

    limitedImages.value = getCryptoImages();

    await loadFrames();
    await loadImage(require('@/assets/opened-bg.webp'));

    atroposInstance.value = new Atropos({
        el: atroposEl.value,
        highlight: true,
        rotate: true,
        rotateXMax: 30,
        rotateYMax: 30,
        shadow: true,
        activeOffset: 50,
    });

});

function getRandomCrypto() {
    const random = Math.random();
    let cumulativeChance = 0;
    for (const crypto of cryptoConfig) {
        cumulativeChance += crypto.chance;
        if (random < cumulativeChance) {
            console.log(crypto);
            return crypto;
            
        }
    }
    return cryptoConfig[cryptoConfig.length - 1];
}

// Открытие коробки
const handleOpenClick = async () => {
    anim.value.stop();
    coinAnimating.value = false;
    startAnimation();
    triggerHapticFeedback("light", "impact");
    if (loading.value || totalCount.value === 0) return;

    loading.value = true;

    try {
        // Запрашиваем следующую коробку
        const box = await getNextUnopenedBox();
        console.log(`Opening box with ID: ${box.id}`);

        // Генерируем выигрыш
        const crypto = getRandomCrypto();
        wonCrypto.value = crypto;
        showModal.value = true;

        // Сохраняем выигрыш на сервере
        await sendWinData(crypto.name, crypto.count, box.id);

        store.commit('decrementBox');
    } catch (err) {
        console.error('Error opening box:', err);
        error.value = 'Failed to open the box.';
    } finally {
        loading.value = false;
    }
};

// Отправка выигрыша на сервер
const sendWinData = async (cryptoName, count, boxId) => {
    try {
        const response = await axios.post('/save-win/', {
            crypto_name: cryptoName,
            count: count,
            box_id: boxId,
        });
        responseData.value = response.data.message;
        console.log('Win record saved:', response.data);
        triggerHapticFeedback("success");
        anim.value.play();
        store.dispatch('winRecords/fetchWinRecords');
    } catch (err) {
        console.error('Error saving win data:', err);
        throw err;
    }
};

</script>

<style scoped>

.animation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1002;
  }
  

:root {
    --coin-margin-right: 20px;  /* отступ от правого края */
    --coin-margin-bottom: 20px; /* отступ от нижнего края */
  }
  
  /* Начальное состояние элемента */
  .winned-coin {
    position: fixed;
    left: 50%;
    top: 140px;
    transform-origin: bottom right; /* изменено */
    transform: translate(-50%, 0) scale(1) rotate(0deg);
    z-index: 4;
    border-radius: 50%;
    border: 2px solid white;
  }
  
  /* Класс, запускающий анимацию */
  .animate-getit {
    animation: moveCoin 2s ease forwards;
  }
  
  /* Ключевые кадры анимации */
  @keyframes moveCoin {
    0% {
      left: 50%;
      top: 140px;
      transform: translate(-50%, 0) scale(1) rotate(0deg);
    }
    100% {
      left: calc(100vw - var(--coin-margin-right));
      top: calc(100vh - var(--coin-margin-bottom));
      transform: translate(0, 0) scale(0.2) rotate(360deg);
    }
  }
  

.device-orient {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    color: white;}

.congrats-container {
    position: absolute;
    top: 18px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    gap: 8px;
}

.congrats-icon {
    width: 25px;
}

.congrats {
    color: white;
    font-size: 20px;
}

.get-win-container {
    position: absolute;
    bottom: 90px;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    white-space: nowrap;
    color: white;
    font-size: 20px;
}

.pixi-container {
    position: relative;
    width: 800px;
    height: 600px;
    overflow: hidden;
  }

.box-icon {
    width: 28px;
    height: 28px;
}

.no-boxes-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    z-index: 4;
    left: 50%;
    top: 16px;
    transform: translateX(-50%);
    position: absolute;
    width: 100%;
}

.more-boxes-buttons {
    display: flex;
    position: absolute;
    bottom: 12px;
    z-index: 5;
    left: 20px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.friend-button {
    width: 294px;
    height: 54px;
    color: #6C47FF;
    background: #FFFFFF;
    border-radius: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.friends-icon {
    width: 20px;
}

.friends-text {
    color: #6C47FF;
    font-size: 17px;
    font-weight: 590;
}

.buy-button {
    width: 294px;
    height: 54px;
    color: #FFFFFF;
    background: #6C47FF;
    border-radius: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.buy-text {
    color: #FFFFFF;
    font-size: 17px;
    font-weight: 590;
}

.buy-icon {
    width: 20px;
}

.no-boxes {
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 590;
}

.blur-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(3px);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.locked-icon {
    width: 140px;
    margin-bottom: 50px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный фон */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    max-width: 300px;
    position: relative;
}

.won-crypto-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.congrats-text {
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
}

.close-button {
    background-color: #6C47FF;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

.image-group {
    display: flex;
    align-items: center;
    position: absolute;
    top: 15px;
    right: 12px;
    z-index: 4;
  }
  
  .image-wrapper {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: -18px; /* небольшое наложение */
    border: 2px solid #2c2f33; /* цвет фона вокруг */
  }
  
  .circle-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .remaining-count {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #42454a; /* цвет фона кружка */
    color: white;
    font-size: 14px;
    font-weight: bold;
    margin-left: -18px;
  }

.card-name{
    position: absolute;
    top: 18px;
    left: 12px;
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    z-index: 4;
}

.first-word{
    color: white;
    font-size: 20px;
    font-weight: 590;
}

.second-word{
    color: rgba(255, 255, 255, 0.486);
    font-size: 20px;
    font-weight: 590;
}

.open-button{
    background: linear-gradient(90deg, #505258, #9e9e9e, #505258);
    color: white;
    font-size: 17px;
    font-weight: 590;
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 294px;
    height: 54px;
    border-radius: 28px;
    z-index: 3;
    border: none;
}

.content {
    position: absolute;
    bottom: 8px;   
    width: 318px;
    height: 369px;
    left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.main-bg {
    width: 110%;
    height: 110%;
    z-index: 2;
}

.border-card {
    position: absolute;
    bottom: 0px;
    z-index: 3;
}
.atropos-inner {
    border-radius: 28px;
    position: relative;
}

.bg {
    width: 100%;
    height: 100%;
    background: #FFFFFF29;
}

.card { 
    width: 334px;
    height: 441px;
    border-radius: 20px;
    position: absolute;
    bottom: 8px;
    left: 8px;
}

.my-atropos {
    width: 334px;
    height: 441px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    margin-left: auto;
    margin-right: auto;
    margin-top: 56px;
    --atropos-rotate-x: 0deg;
    --atropos-rotate-y: 0deg;
  }

  .get-button {
    background: #fff;
    border-radius: 50px;
    color: #6c47ff;
    font-size: 16px;
    font-weight: 700;
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .get-win-button {
    display: flex;
    position: absolute;
    bottom: 12px;
    z-index: 5;
    left: 50%;
    right: 50%;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

</style>
